<template>
<div class="tierlistrelics">
  <div class="hero-nav" :style="{ backgroundImage: 'url(' + bgURL + ')' }">
      <h1>{{character.replace(/\b\w/g, (l) => { return l.toUpperCase() })}} Relics Tier List</h1>
  </div>

  <div class="character-change-button fixed bottom-0 inset-x-0 z-50 w-full h-16 md:h-auto">
    <div class="w-full px-1 md:px-3">
      <router-link class="absolute right-0 z-50 -mt-12 mr-2 md:-mb-12 md:mr-5 w-1/3 md:w-1/5 bg-gray-700 p-3 rounded-lg md:bottom-0" :to="{name: 'sls-tierlist-card', params:{ character: character}}">
        <p>jump to cards</p>
      </router-link>
      <div class="relative flex w-full">
        <router-link class="bg ironclad w-1/4 my-2 mx-1 md:mx-2 rounded-lg" :class="{ 'selected': character === 'ironclad' }" :to="{name: 'sls-tierlist-relic', params:{ character: 'ironclad'}}">
          <p>ironclad</p>
        </router-link>

        <router-link class="bg silent w-1/4 my-2 mx-1 md:mx-2 rounded-lg" :class="{ 'selected': character === 'silent' }" :to="{name: 'sls-tierlist-relic', params:{ character: 'silent'}}">
          <p>silent</p>
        </router-link>

        <router-link class="bg defect w-1/4 my-2 mx-1 md:mx-2 rounded-lg" :class="{ 'selected': character === 'defect' }" :to="{name: 'sls-tierlist-relic', params:{ character: 'defect'}}">
          <p class="text">defect</p>
        </router-link>

        <router-link class="bg watcher w-1/4 my-2 mx-1 md:mx-2 rounded-lg" :class="{ 'selected': character === 'watcher' }" :to="{name: 'sls-tierlist-relic', params:{ character: 'watcher'}}">
          <p class="text">watcher</p>
        </router-link>
      </div>
    </div>
  </div>

  <div class="main">
      <Adsense
        v-show="!prerenderHideElement"
        :key="rerenderad"
        class="ga"
        data-ad-client="ca-pub-5393590966220416"
        data-ad-slot="3351730842"
        data-ad-format="auto">
      </Adsense>
      <div class="content">
        <aside class="aside">
          <div class="search sticky">
            <searchtierlist ref="searchtierchild" class="searchtier" type="relics" :character="character" @highlight="searchHighlighter"/>
            <!-- <recentlyviewed
              @addCard="addCard()"
            /> -->
            <Adsense
              v-show="!prerenderHideElement"
              :key="rerenderad"
              data-ad-client="ca-pub-5393590966220416"
              data-ad-slot="4244264203"
              :data-ad-format="asideAdvertSizeRandomiser()"
              ins-class="adslot-aside"
            >
            </Adsense>
          </div>
        </aside>

        <section class="">
          <h2>S Tier</h2>
            <transition-group class="relic-list" v-if="relicTier('S').length > 0" name="fade" tag="ul"
              >
              <!-- <transition-group name="fade"> -->
              <li v-for="(relic, index) in relicTier('S')" :key="index" :id="`relic-${index}`" @click="openItemModal(relic.name)" :ref="relic.name">
                <relic
                  class="relic"
                  :class="{'relic-highlight': relic.name === searchHighlight}"
                  :character="relic.character"
                  :title="relic.name"
                  :rarity="relic.rarity"
                  :description="relic.description">
                </relic>
              </li>
              </transition-group>
            <!-- </ul> -->
            <div v-else>
              <p class="no-relics">No Relics</p>
            </div>
            <h2>A Tier</h2>
            <ul class="relic-list" v-if="relicTier('A').length > 0">
              <li v-for="(relic, index) in relicTier('A')" :key="index" :id="`relic-${index}`" @click="openItemModal(relic.name)" :ref="relic.name">
                <relic
                  class="relic"
                  :class="{'relic-highlight': relic.name === searchHighlight}"
                  :character="relic.character"
                  :title="relic.name"
                  :rarity="relic.rarity"
                  :description="relic.description">
                </relic>
              </li>
            </ul>
            <div v-else>
              <p class="no-relics">No Relics</p>
            </div>
            <h2>B Tier</h2>
            <ul class="relic-list" v-if="relicTier('B').length > 0">
              <li v-for="(relic, index) in relicTier('B')" :key="index" :id="`relic-${index}`" @click="openItemModal(relic.name)" :ref="relic.name">
                <relic
                  class="relic"
                  :class="{'relic-highlight': relic.name === searchHighlight}"
                  :character="relic.character"
                  :title="relic.name"
                  :rarity="relic.rarity"
                  :description="relic.description">
                </relic>
              </li>
            </ul>
            <div v-else>
              <p class="no-relics">No Relics</p>
            </div>
            <h2>C Tier</h2>
            <ul class="relic-list" v-if="relicTier('C').length > 0">
              <li v-for="(relic, index) in relicTier('C')" :key="index" :id="`relic-${index}`" @click="openItemModal(relic.name)" :ref="relic.name">
                <relic
                  class="relic"
                  :class="{'relic-highlight': relic.name === searchHighlight}"
                  :character="relic.character"
                  :title="relic.name"
                  :rarity="relic.rarity"
                  :description="relic.description">
                </relic>
              </li>
            </ul>
            <div v-else>
              <p class="no-relics">No Relics</p>
            </div>
            <h2>D Tier</h2>
            <ul class="relic-list" v-if="relicTier('D').length > 0">
              <li v-for="(relic, index) in relicTier('D')" :key="index" :id="`relic-${index}`" @click="openItemModal(relic.name)" :ref="relic.name">
                <relic
                  class="relic"
                  :class="{'relic-highlight': relic.name === searchHighlight}"
                  :character="relic.character"
                  :title="relic.name"
                  :rarity="relic.rarity"
                  :description="relic.description">
                </relic>
              </li>
            </ul>
            <div v-else>
              <p class="no-relics">No Relics</p>
            </div>
            <h2>E Tier</h2>
            <ul class="relic-list" v-if="relicTier('E').length > 0">
              <li v-for="(relic, index) in relicTier('E')" :key="index" :id="`relic-${index}`" @click="openItemModal(relic.name)" :ref="relic.name">
                <relic
                  class="relic"
                  :class="{'relic-highlight': relic.name === searchHighlight}"
                  :character="relic.character"
                  :title="relic.name"
                  :rarity="relic.rarity"
                  :description="relic.description">
                </relic>
              </li>
            </ul>
            <div v-else>
              <p class="no-relics">No Relics</p>
            </div>

            <h2>F Tier</h2>
            <ul class="relic-list" v-if="relicTier('F').length > 0">
              <li  v-for="(relic, index) in relicTier('F')" :key="index" :id="`relic-${index}`" @click="openItemModal(relic.name)" :ref="relic.name">
                <relic
                  class="relic"
                  :class="{'relic-highlight': relic.name === searchHighlight}"
                  :character="relic.character"
                  :title="relic.name"
                  :rarity="relic.rarity"
                  :description="relic.description">
                </relic>
              </li>
            </ul>
            <div v-else>
              <p class="no-relics">No Relics</p>
            </div>
        </section>
      </div>
  </div>

  <transition name="modal">
    <itemdetail
      class="item-detail-modal"
      v-if="singleItemNameForModal !== null && singleItemNameForModal.length > 0"
      :itemName="singleItemNameForModal"
      :itemType="singleItemTypeForModal"
      :isModal="true"
      @closeCardModal="closeCardModal"
      @changeItemName="openItemModal"
    />
  </transition>

</div>

</template>

<script>
import allData from './../components/carddata.json';
import relic from './../components/relic';
import itemdetail from './../components/itemdetail';
import recentlyviewed from './../components/recentlyviewedlist';
import searchtierlist from './../components/searchTier';
// import cards from 'cards';

export default {
  name: 'sls-tierlist',
  components: { relic, itemdetail, recentlyviewed, searchtierlist },
  data() {
    return {
      singleItemNameForModal: '',
      singleItemTypeForModal: '',
      rerenderad: 0,
      prerenderHideElement: false,

      // this is the card clicked in a search
      searchHighlight: '',

    };
  },
  head: {
    title() {
        return {
          inner: this.metaTitle,
          separator: '|',
          complement: 'SpireSpy',
        };
    },
    meta() {
      return [
        { name: 'description', content: this.metaDesc },

        // Google
        { itemprop: 'name', content: this.metaTitle },
        { itemprop: 'description', content: this.metaDesc },
        { itemprop: 'image', content: this.metaImg },

        // Facebook
        { property: 'og:title', content: this.metaTitle },
        { property: 'og:description', content: this.metaDesc },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'Maybe Later Games' },
        { property: 'og:url', content: this.metaPageURL },
        { property: 'og:image', content: this.metaImg },
        // { property: 'fb:app_id', content: '123456789' },
        // { property: 'og:locale', content: 'en_US' },
        // { property: 'article:author', content: '' },

        // Twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: this.metaTitle },
        { name: 'twitter:description', content: this.metaDesc },
        { name: 'twitter:image', content: this.metaImg },

      ];
    },
    link () {
      return [
      { rel: 'canonical', href: 'https://maybelatergames.co.uk/spirespy/relics/' + this.$route.params.character + '/tierlist',  id: 'canonical' },
      ]
    }
  },
  computed: {
    // need characters
    character() {
      return this.$route.params.character;
    },
    // characterBG: function(){
    //   return `background-image: url('` + this.bgURL + `')`;
    //   // return require(`./../assets/backgrounds/${this.character}Portrait.jpg`);
    // },
    bgURL() {
      return require(`./../assets/backgrounds/${this.character}Portrait.jpg`);
    },

    // Meta Data
    metaTitle() {
      return 'The ' + this.character.replace(/\b\w/g, (l) => l.toUpperCase()) + ' Relic Tier List';
    },
    metaDesc() {
      return `A list of relics organised by tier rating for the ${this.character
      .replace(/\b\w/g, (l) => l.toUpperCase())}.`;
    },
    metaImg() {
      return `https://maybelatergames.co.uk${this.bgURL}`;
    },
    metaPageURL() {
      return `https://maybelatergames.co.uk${this.$route.fullPath}`;
    },
  },
  methods: {
    relicTier(tier) {
      const thisTier = allData.relics.filter((item) =>
        item[`tier${this.character}`] !== 'X' && item[`tier${this.character}`] !== '',
      );
      const plus = thisTier.filter((item) => item[`tier${this.character}`] === `${tier}+`);
      const norm = thisTier.filter((item) => item[`tier${this.character}`] === `${tier}`);
      const minus = thisTier.filter((item) => item[`tier${this.character}`] === `${tier}-`);
      const sorted = plus.concat(norm, minus);
      return sorted;
    },

    // Modal Functions
    itemPureName(name) {
      return name.replace(/\s/g, '').replace(/\'/g, '').replace(/\-/g, '').toLowerCase();
    },

    openItemModal(itemName, type = 'relics') {
      // console.log('modal');
      const name = this.itemPureName(itemName);
      this.singleItemNameForModal = name;
      this.singleItemTypeForModal = type;
    },
    closeCardModal() {
      this.singleItemNameForModal = '';
    },
    keyPlusOne() {
      this.rerenderad = this.rerenderad + 1;
    },
    asideAdvertSizeRandomiser() {
      if (Math.random() < 0.5) {
        return 'rectangle';
      }
      return 'auto';
    },
    // addToRecentlyViewed() {

    // },
    searchHighlighter(relicName) {
      // console.log(cardName);
      this.searchHighlight = relicName;
      setTimeout(() => {
        this.searchHighlight = '';
      }, 4000);
    },
    focusSearch() {
      this.$refs.searchtierchild.$refs.tierlistSearchInput.focus();
    },
    // checks for letters and numbers and that search is not focused
    focusChecker(event) {
      if (document.activeElement !== this.$refs.searchtierchild.$refs.tierlistSearchInput) {
        if ( (event.keyCode >= 48 && event.keyCode <= 57) // numbers
             || (event.keyCode >= 65 && event.keyCode <= 90) // letters
             || event.keyCode === 27) { // esc
          this.focusSearch();
        }
      }
    },
  },
  watch: {
    $route() {
      this.keyPlusOne();
    },
  },
  mounted() {
    if (typeof window.__PRERENDER_INJECTED !== 'undefined') {
      this.prerenderHideElement = window.__PRERENDER_INJECTED.prerenderHide;
    }
    window.addEventListener('keydown', this.focusChecker);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.focusChecker);
  },
};
</script>

<style lang="scss" scoped>

@mixin for-phone-only {
  @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) { @content; }
}


.tierlistrelics {
  cursor: url('./../assets/cursor/mouse.png'), auto;

  background: rgba(#10212B, 0.85);
  background: rgba(#10212B, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  // width: 100%;
}

.main {
  position: relative;
  width: 100%;
  // max-width: 1280px;
  // margin: auto;
  // padding-bottom: 100px;
  padding: 30px 30px 100px;
  @include for-tablet-portrait-up {
    padding: 40px 40px 100px;
  }
}

h1 {
  font-size: 3.6em;
  font-weight: 500;
  line-height: 1.2em;
  text-align: center;
  text-shadow: 4px 4px rgba(0,0,0,0.6)
}

h2 {
  font-size: 2.5em;
  // margin: 20px 0 0;
  // box-shadow:   rgba(#010f16, 0.8) 0 -4px 0 0, rgba(#746e53, 1) 0 -7px 0 0, rgba(#c9bf92, 1) 0 -12px 0 0;
  // padding-top: 30px;
  // position: sticky;
  // top: 136px;
  // background: #152f38;
}

.hero-nav {
  width: 100%;
  background-size: cover;
  background-position-x: right;
  // margin-top: 60px;
  height: 300px;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  @include for-tablet-landscape-up{
    height: 200px;
    background-position-y: center;
  }
}

.character-change-button {

    background: #213641;
    border-top: 2px solid #273f4d;
    transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
    @include for-tablet-landscape-up {

      // grid-template-columns: repeat( 3, 1fr);
      grid-gap: 30px;
      position: -webkit-sticky;
      position: sticky;
      top: 60px;
      background: #183a46;
      border-bottom: 2px solid #2a3e44;
      box-shadow: rgba(#1c4757, 1) 0 1px 0 0, rgba(#000000, 0.3) 0 4px 3px 0;

    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 100%;
      height: 38px;
      // border-radius: 20px;


      > p {
        display: block;
        font-size: 1em;
        text-transform: uppercase;
        text-shadow: 2px 2px rgba(#000, 0.5);
        font-weight: 400;
        position: relative;
        z-index: 1;
        opacity: 0.8;
        margin: 0;
        transition: cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
      }
      @media (hover: hover) {
        &:hover {
          transform: scale(1.05);
          transition: 0s linear;

          > p {
            opacity: 1;
            transform: scale(1.3);
            transition: 0s linear;
          }
        }
      }
    }

    > .ironclad-bg {
      &::after {
        background-image: url('./../assets/backgrounds/ironcladPortrait.jpg');
        background-position: center center;
      }
      background-color: rgba(16, 33, 43, 1);
      @media (hover: hover) {
        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }
    }
    .ironclad {
      background: rgb(99, 31, 41);
      @media (hover: hover) {
        &:hover {
          background: rgb(117, 28, 42);
        }
      }
    }

    > .silent-bg {
      &::after {
        background-image: url('./../assets/backgrounds/silentPortrait.jpg');
        background-position: center center;
      }
      background-color: rgba(16, 33, 43, 1);
      @media (hover: hover) {
        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    .silent {
      background: rgb(31, 99, 46);
      &:hover {
        background: rgb(28, 117, 50);
      }
    }

    > .defect-bg {
      &::after {
        background-image: url('./../assets/backgrounds/defectPortrait.jpg');
        background-position: center center;
      }
      background-color: rgba(16, 33, 43, 1);
      @media (hover: hover) {
        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    .defect {
      background: #1C5276;
      @media (hover: hover) {
        &:hover {
          background: rgb(31, 91, 131);
        }
      }
    }

    > .watcher-bg {
      &::after {
        background-image: url('./../assets/backgrounds/watcherPortrait.jpg');
        background-position: center center;
      }
      background-color: rgb(46, 46, 46);
      @media (hover: hover) {
        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }
    }

    .watcher {
      background: rgb(88, 31, 102);
      @media (hover: hover) {
        &:hover {
          background: rgb(107, 25, 128);
        }
      }
    }

    .selected {
      // transform: scale(1.015);
      > p {
        opacity: 1;
        transform: scale(1.2);
        transition: 0s linear;
      }

    }
  }

// .card-list {
//   list-style: none;
//   display: flex;
//   flex-wrap: wrap;
//   padding: 10px 40px;
//   margin: 0 auto;
//   > li {
//     width: 17%;
//     // width: 45%;
//     margin: 1% 1.5%;
//     display: flex;
//     align-items: flex-end;
//     // overflow: hidden;
//     transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);
//     // transition: scale 0.5s easing's easeOutCubic curve;
//     &:hover {
//       transform: scale(1.4, 1.4);
//       transition: 0s;
//       z-index: 100;
//       & ~ #sts-card-base-img {
//         box-shadow: 0 0 20px 20px rgba(#000, 0.4);
//       }
//       // box-shadow: 0 0 20px 20px rgba(#000, 0.4);
//     }
//   }
// }

.relic-list {
display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-gap: 20px;
  width: 100%;
  list-style: none;

  // position: relative;
  // max-width: 1280px;
  margin: auto;
  padding: 30px 0;
  justify-content: center;
  align-items: center;

  // border-style: solid;
  // border-color:  #c9bf92;
  // border-width: 5px 0 5px 0;
  // box-shadow: inset #797356 0 4px 0 0, inset rgba(0, 0, 0, 0.3) 0 10px 0 0, #797356 0 4px 0 0, rgba(0, 0, 0, 0.3) 0 12px 2px 0;

  @include for-tablet-portrait-up {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @include for-tablet-landscape-up {
    grid-gap: 30px;
    // padding: 50px 0;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }

  @include for-desktop-up {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    // border-width: 5px 5px 5px 5px;
    border-radius: 16px;
    // grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  > li {

    transition: 620ms cubic-bezier(0.215, 0.61, 0.355, 1);
    // transition: scale 0.5s easing's easeOutCubic curve;
    @media (hover: hover) {
      &:hover {
        transform: scale(1.4, 1.4);
        transition: 0s;
        z-index: 10;
        & ~ #sts-relic-base-img {
          box-shadow: 0 0 20px 20px rgba(#000, 0.4);
        }
        //box-shadow: 0 0 20px 20px rgba(#000, 0.4);
      }
    }
  }
}

.no-relics {
  text-align: center;
  font-size: 1.5em;
  margin: 80px 0 120px;
}

// Modal
.item-detail-modal {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(10px)
}

// Modal Animation
.modal-enter-active, .modal-leave-active {
  transition: .3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.modal-enter, .modal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  // opacity: 0;
  transform: translateY(100vh)
}

.ga {
  margin-bottom: 50px;
  text-align: center;
}

// v2
.content {
  @include for-tablet-landscape-up {
    display: grid;
    grid-template-columns: 220px 5fr;
    grid-gap: 40px;
  }
}

.aside {
  display: flex;
  align-items: flex-start;
  width: 100%;
  z-index: 60;
  position: relative;
  .ad {
    // display: none;
    max-height: 600px;
    @include for-phone-only {
      display: none;
    }
  }
}

.sticky {
  top: 180px;
  // background-color: yellow;
  // padding-top: 100px;
  // height: 200px;
  overflow: visible;

  @include for-tablet-landscape-up {
    position: -webkit-sticky;
    position: sticky;
  }
}

.relic {
  cursor: url('./../assets/cursor/magnifier.png'), pointer;

  // background: #18abcf;
  // border: 2px solid #1885a0;
  // outline: 2px #1885a0;
  border-radius: 5px;
  // animation: ripples 1s linear infinite;
  // 18abcf
  box-shadow: 0 0 rgba(#18d1ff, 0.4), 0 0 0 0 rgba(#18d1ff, 0.2),
    0 0 0 0 rgba(#18d1ff, 0.2), 0 0 0 0 rgba(#18d1ff, 0.2);

}

.relic-highlight {
  box-shadow: 0 0 rgba(#18d1ff, 0.4), 0 0 0 4px rgba(#18d1ff, 0.2),
    0 0 0 8px rgba(#18d1ff, 0.2), 0 0 0 12px rgba(#18d1ff, 0.8);
  animation: ripples 1s linear infinite;
  transition: 0.3s ease-in-out;
}

@keyframes ripples {
  to {
    box-shadow: 0 0 0 4px rgba(#18d1ff, 0.2), 0 0 0 8px rgba(#18d1ff, 0.2),
      0 0 0 12px rgba(#18d1ff, 0.2), 0 0 0 16px rgba(#18d1ff, 0);
  }
}

.searchtier {
  position: fixed;
  // position: relative;
  // top: 64px;
  top: 0;
  left: 0;
  z-index: 100;
  @include for-tablet-landscape-up {
    position: relative;
  }

}

.advert {
  background: #000000;
  height: 220px;
  width: 100%;
}

.adslot-aside {
  max-height: 460px;
  background: #10212B;
}
</style>
